<template>
  <header class="main-header" sticky v-if="$locale">
    <div class="main-header-container">
      <div class="main-header-container-options">
        <router-link class="nav-button logo" to="/">
          <figure><img :src="$locale['nav-image']" /></figure>
        </router-link>
      </div>
      <div class="main-header-container-user">
        <nav class="main-header-nav nav">
          <router-link class="nav-button" to="/">{{ $locale["nav-home-btn"] }}</router-link>
          <router-link class="nav-button" to="/services">{{ $locale["nav-services-btn"] }}</router-link>
          <router-link class="nav-button" to="/about">{{ $locale["nav-about-btn"] }}</router-link>
          <router-link class="nav-button" to="/jobs">{{ $locale["nav-job-btn"] }}</router-link>
          <router-link class="nav-button" to="/contact">{{ $locale["nav-contact-btn"] }}</router-link>
        </nav>
        <LanguageSwitch />
        <HamburgerBtn />
      </div>
    </div>
    <UserMenu :user="$user" ref="myUserMenu" noprint />
    <MobileMenu ref="MobileMenu" :locale="$locale" noprint />
  </header>
</template>

<script>
import UserMenu from "../user/userMenu.vue";
import LanguageSwitch from "../widgets/LanguageSwitch.vue";
import HamburgerBtn from "./HamburgerBtn.vue";
import MobileMenu from "./MobileMenu.vue";
export default {
  components: { UserMenu, LanguageSwitch, HamburgerBtn, MobileMenu },
  props: {
    options: Object,
  },
  methods: {
    isVisible: function (feature) {
      if (this.options?.remove?.includes(feature)) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.main-header {
  position: fixed;
  width: calc(100% - #{$mpadding});
  height: $header_height;
  margin: $mpadding/2;
  color: $white;
  top: 0;
  z-index: 10;
  &-title {
    font-size: 120%;
  }
  &-container {
    background: $primary_color;
    border-radius: $mpadding/2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .logo {
      display: block;
      margin: $mpadding/2 $mpadding;
    }
    &-user {
      display: flex;
      align-items: center;
      align-self: flex-start;
      height: 100%;
      .avatar {
        display: table;
        figure {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: $primary_color;
          color: white;
          border-radius: 50%;
          margin: 10px;
          cursor: pointer;
          user-select: none;
          background-size: cover;
          box-shadow: $dshadow;
        }
        &:active {
          opacity: 0.8;
        }
      }
      .name {
        display: none;
      }
      &-button {
        padding: 0 $mpadding;
        color: $primary_color;
        cursor: pointer;
        height: $header_height;
        display: flex;
        align-items: center;
        .iconic {
          font-size: 120%;
        }
        &:active {
          opacity: 0.8;
        }
      }
    }
    .language-switch {
      padding: 0 $mpadding;
      display: none;
    }
  }
  &-nav {
    display: none;
    .nav {
      position: relative;
      &-button {
        display: inline-block;
        padding: $mpadding;
        opacity: 0.5;
        transition: 0.3s ease-in-out;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  @media (min-width: $tablet_width) {
    &-nav {
      display: flex;
      line-height: 24px;
      a {
        position: relative;
        &.router-link-exact-active {
          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 20px;
            height: 1px;
            background: white;
            right: calc(50% - 10px);
          }
        }

        &:hover,
        &.router-link-exact-active {
          opacity: 1;
        }
      }
    }
    &-container {
      &-user {
        .avatar {
          figure {
            width: 38px;
            height: 38px;
            line-height: 38px;
            margin: 5px 10px;
          }
        }
        .name {
          display: block;
        }
      }
      .language-switch {
        display: block;
      }
    }
    .HambuegerBtn {
      display: none;
    }
  }
  @media (min-width: $pc_width) {
    &-nav {
      .nav {
        &-button {
          padding: $mpadding $mpadding * 2;
        }
      }
    }
  }
}
</style>
