<template>
  <div class="HambuegerBtn">
    <a @click="mobileMenu">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    mobileMenu: function() {
      const myBody = document.querySelector("body");
      myBody.classList.toggle("onmenu");
    },
  },
};
</script>

<style lang="scss">
.HambuegerBtn {
  display: flex;
  padding: $mpadding;
  a {
    width: 24px;
    cursor: pointer;
    .line {
      margin: 6px 0;
      background-color: rgb(255, 255, 255);
      width: 100%;
      height: 2px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: all 450ms ease-in;
      -moz-transition: all 450ms ease-in;
      -o-transition: all 450ms ease-in;
      transition: all 450ms ease-in;
    }
  }
}
*.onmenu {
  .HambuegerBtn a .line:nth-child(1) {
    -webkit-transform: translateY(410%) rotate(40deg);
    -ms-transform: translateY(410%) rotate(40deg);
    transform: translateY(410%) rotate(40deg);
  }
  .HambuegerBtn a .line:nth-child(2) {
    opacity: 0;
  }
  .HambuegerBtn a .line:nth-child(3) {
    -webkit-transform: translateY(-370%) rotate(138deg);
    -ms-transform: translateY(-370%) rotate(138deg);
    transform: translateY(-370%) rotate(138deg);
  }
}
</style>
