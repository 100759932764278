<template>
  <div class="social-block" v-if="locale">
    <div class="social-block-bulletin">
      <div class="social-block-bulletin-item">{{ locale["bulletin-label"] }}</div>
      <div class="social-block-bulletin-item">
        <div class="bulletin">
          <input type="email" :placeholder="locale['email-input']" w100 />
          <a class="bulletin__send"><iconic name="arrowSend" /></a>
        </div>
      </div>
    </div>
    <div class="footer-left-item social-block-networks">
      <!-- <a class="social-block-networks-btn" :href="locale['facebook-link']" target="_blank"><iconic name="facebook" /></a>
      <a class="social-block-networks-btn" :href="locale['linkedin-link']" target="_blank"><iconic name="linkedin_in" /></a>
      <a class="social-block-networks-btn" :href="locale['instgram-link']" target="_blank"><iconic name="instagram" /></a> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    locale: Object,
  },
};
</script>

<style lang="scss">
.social-block {
  position: relative;
  &-networks {
    display: flex;
    justify-content: center;
    margin: $mpadding 0;
    &-btn {
      display: inline-block;
      width: 34px;
      height: 34px;
      background: #fff;
      color: #000;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:not(:first-child) {
        margin-left: $mpadding;
      }
    }
  }
  &-bulletin {
    text-align: left;
    padding-bottom: 7px;
    &-item {
      margin-top: $mpadding;
      .bulletin {
        display: flex;
        padding-left: 16px;
        border-left: solid 1px $alto;
        border-bottom: solid 1px $alto;
        border-top: solid 1px $alto;
        border-radius: 16px;
        &__send {
          width: 74px;
          height: 34px;
          background: #fff;
          color: #000;
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        input {
          font-size: 100%;
          border: none;
          background: none;
          color: #fff;
        }
      }
    }
  }
  @media (min-width: $tablet_width) {
    &-networks {
      justify-content: left;
      margin-bottom: 0;
    }
  }
}
</style>
