<template>
  <label class="toggle language-switch display-flex">
    <input class="toggle-checkbox" type="checkbox" :checked="current === 'en' ? true : false" @change="setLanguage($event)" />
    <div class="toggle-switch"><iconic v-if="isLoading" name="gspinner" /></div>
  </label>
</template>

<script>
export default {
  props: {
    checked: Boolean,
  },
  data: function() {
    return {
      current: localStorage.getItem(process.env.VUE_APP_LANG_KEY),
    };
  },
  methods: {
    setLanguage: function($event) {
      if ($event.srcElement.checked) {
        localStorage.setItem(process.env.VUE_APP_LANG_KEY, "en");
      } else {
        localStorage.setItem(process.env.VUE_APP_LANG_KEY, "es");
      }
      this.$store.dispatch("startApp");
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.loadingContent;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  cursor: pointer;
  display: inline-block;
}
.toggle-switch {
  display: inline-block;
  background: url("https://onegroup.s3.amazonaws.com/website/english_flag.svg") 100% center no-repeat #cecece;
  background-position: center right 7px;
  background-size: 18px;
  border-radius: 16px;
  width: 54px;
  height: 28px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
  &:before,
  &:after {
    content: "";
  }
  &:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
  }
  .iconic {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 4px;
    left: 4px;
  }
  .toggle:hover &:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  }
  .toggle-checkbox:checked + & {
    background: url("https://onegroup.s3.amazonaws.com/website/spanish_flag.svg") 100% center no-repeat #cecece;
    background-position: center left 7px;
    background-size: 18px;
    &:before,
    .iconic {
      left: 30px;
    }
  }
  .iconic {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}
</style>
