<template>
  <div class="user-menu" v-if="user">
    <div class="user-menu-bgwrap" @click="toggleMenu"></div>
    <div class="user-menu-content main">
      <div class="user-menu-card">
        <div class="user-menu-card-avatar">
          <figure :style="`background-image: url(${user.picture});`">
            <span v-if="!user.picture">{{ user.name.charAt(0) }}</span>
          </figure>
        </div>
        <div class="user-menu-card-header">
          <div>{{ user.name }}</div>
          <div>
            <small>{{ user.email }}</small>
          </div>
        </div>
        <div class="user-menu-card-option"><a @click="closeSession">Cerrar sesión</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../../router";
export default {
  props: {
    user: Object,
  },
  methods: {
    toggleMenu: function() {
      const element = document.querySelector(".user-menu");
      element.classList.toggle("active");
    },
    closeSession: function() {
      localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
      router.push({ path: "/login" });
      this.toggleMenu();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";
.user-menu {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &-card {
    position: relative;
    z-index: 2;
    background: $white;
    margin: $mpadding;
    margin-top: 53px;
    width: 300px;
    border-radius: $mradius;
    overflow: hidden;
    text-align: center;
    float: right;
    border-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 10px #00000052;
    border: 1px solid #ccc;
    overflow: hidden;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    &-avatar {
      figure {
        width: 76px;
        height: 76px;
        display: inline-block;
        border-radius: 50%;
        background: $primary_color;
        color: $white;
        line-height: 76px;
        font-size: 200%;
        margin: $mpadding $mpadding $mpadding/2 $mpadding;
        background-size: cover;
        box-shadow: $dshadow;
      }
    }
    &-header {
      border-bottom: solid 1px $chicago;
      padding: $mpadding/2 $mpadding;
    }
    &-option {
      a {
        display: block;
        padding: $mpadding;
        cursor: pointer;
        &:hover {
          background: $alto;
        }
      }
    }
  }
  &.active {
    display: block;
  }
  &-bgwrap {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}
</style>
