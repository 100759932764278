<template>
  <div class="mobile-menu" v-if="locale">
    <div class="mobile-menu-wrap"></div>
    <div class="mobile-menu-content">
      <nav class="mobile-menu-nav">
        <div class="mobile-menu-nav-button">
          <LanguageSwitch />
        </div>
        <div class="mobile-menu-nav-button">
          <router-link to="/" @click.native="mobileMenu">{{ locale["nav-home-btn"] }}</router-link>
        </div>
        <div class="mobile-menu-nav-button">
          <router-link to="/services" @click.native="mobileMenu">{{ locale["nav-services-btn"] }}</router-link>
        </div>
        <div class="mobile-menu-nav-button">
          <router-link to="/about" @click.native="mobileMenu">{{ locale["nav-about-btn"] }}</router-link>
        </div>
        <div class="mobile-menu-nav-button">
          <router-link to="/jobs" @click.native="mobileMenu">{{ locale["nav-job-btn"] }}</router-link>
        </div>
        <div class="mobile-menu-nav-button">
          <router-link to="/contact" @click.native="mobileMenu">{{ locale["nav-contact-btn"] }}</router-link>
        </div>
      </nav>
      <SocialBlock :locale="locale" />
    </div>
  </div>
</template>

<script>
import LanguageSwitch from "../widgets/LanguageSwitch.vue";
import SocialBlock from "./SocialBlock.vue";
export default {
  components: { SocialBlock, LanguageSwitch },
  props: {
    locale: Object,
  },
  methods: {
    mobileMenu: function() {
      const myBody = document.querySelector("body");
      myBody.classList.remove("onmenu");
    },
  },
};
</script>

<style lang="scss">
.mobile-menu {
  @include Fixed;
  background: rgba(0, 0, 0, 0.411);
  transform: translateX(100%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  &-wrap {
    @include Fixed;
    background: #d8d8d86b;
    backdrop-filter: blur(10px);
  }
  &-content {
    padding: $mpadding * 2;
    margin: $mpadding/2;
    background: rgba(0, 0, 0, 0.8);
    border-radius: $mpadding/2;
    position: relative;
    top: $header_height + $mpadding/2;
    text-align: right;
    height: calc(100% - #{$header_height + $mpadding * 1.5});
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  &-nav {
    font-family: Blinker;
    font-weight: 600;
    &-button {
      display: block;
      font-size: 130%;
      margin-bottom: $mpadding * 1.5;
    }
  }
  .social-block {
    margin-top: auto;
    & > * {
      text-align: right;
    }
    &-networks {
      justify-content: flex-end;
    }
    &-bulletin {
      &-item {
        &:first-child {
          font-family: Blinker;
          font-weight: 600;
        }
      }
    }
  }
}
body.onmenu {
  overflow: hidden;
  .main-header {
    &-container {
      z-index: inherit;
    }
  }
  .mobile-menu {
    background: rgba(0, 0, 0, 0.411);
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
